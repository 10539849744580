import React from "react";
import {Container, MuiThemeProvider} from '@material-ui/core';
import { createMuiTheme, withTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import PrivatePage from "./PrivatePage";
import './App.css';
import SignPage from "./sign/SignPage";
import {commonUtils_IsAuthenticated} from "./utils/common.utils";
import UnsubscribePage from "./mailing/UnsubscribePage";
import ExamplePage from "./examples/ExamplePage";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0162CA"
        },
        secondary: {
            main: "#DBE5EE"
        }
    }
});

class PrivateRoute extends React.Component {

    render() {
        let {children, ...rest} = this.props;
        return (
            <Route
                {...rest}
                render={({location}) =>
                    commonUtils_IsAuthenticated() ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/sign-in",
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
        );
    }
}

class App extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Container style={{width: 1240, maxWidth: 1240, minWidth: 1240, margin: "0 auto", padding: "0 20px", clear: "both"}}>
                    <Router>
                        <Switch>
                            <Route path="/sign-in">
                                <SignPage/>
                            </Route>
                            <Route path="/sign-out">
                                <SignPage out={1}/>
                            </Route>
                            <Route path="/sign-up">
                                <SignPage/>
                            </Route>
                            <Route path="/password-recovery">
                                <SignPage/>
                            </Route>
                            <Route path="/password-recovery-request">
                                <SignPage/>
                            </Route>
                            <Route path="/account-activation">
                                <SignPage/>
                            </Route>
                            <Route path="/mailing-unsubscribe">
                                <UnsubscribePage/>
                            </Route>
                            <Route path="/example">
                                <ExamplePage/>
                            </Route>
                            <PrivateRoute path="/">
                                <PrivatePage/>
                            </PrivateRoute>
                        </Switch>
                    </Router>
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default withTheme(App);