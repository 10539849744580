import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {commonUtils_GetApiCallOptions, commonUtils_GetApiEndpoint, commonUtils_RedirectTo} from "../utils/common.utils";
import {layoutUtils_LoadingEnd} from "../utils/layout.utils";
import {researchUtils_RenderTagCloudDetail} from "../utils/research.utils";

const useStyles = makeStyles({
	root: {

	}
});

export default function ExampleTagCloud7PhrasesSimilarity(props) {
	const classes = useStyles();

	useEffect(function (){
		fetch(
			commonUtils_GetApiEndpoint('example/example'),
			commonUtils_GetApiCallOptions({e: "ExampleTagCloud7PhrasesSimilarity"})
		)
			.then(blob => blob.json())
			.then(response => {
				researchUtils_RenderTagCloudDetail(7, response.data);
				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
	});

	return (
		<div>
			<h1>Podobnost frází</h1>
			<h4>Která tři slovní spojení vás napadnou ve spojení se značkou?</h4>
			<ol>
				<li>Data načtena metodou "Bez čištění"</li>
				<li>Fráze se porovnají každá s každou a vypočítá se podobnost v %.</li>
				<li>Podle nastavených thresholdů se určí, zda se fráze sloučí. Při slučování se kontroluje, zda si slučované fráze vzájemně neodporují, tj. např. kvalitní x nekvalitní jsou velmi podobné, ale mají opačný význam.</li>
			</ol>
			<div id="QRD_7"> </div>
		</div>

	);
}
