import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: props => ({
		width: 48,
		float: "left",
		marginRight: props.marginRight,
		"& .icon-bg": {
			borderRadius: 8,
			float: "left",
			marginRight: 16,
			"& .icon": {
				width: 24,
				height: 24,
				margin: 12
			},
			"& .icon.document-check-orange": {
				background: "transparent url('/icon/document-check-orange.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.price-orange": {
				background: "transparent url('/icon/price-orange.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.price-green": {
				background: "transparent url('/icon/price-green.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.price-red": {
				background: "transparent url('/icon/price-red.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.clock-blue": {
				background: "transparent url('/icon/clock-blue.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.circle-check-green": {
				background: "transparent url('/icon/circle-check-green.png') 0% 0% no-repeat padding-box",
			},
			"& .icon.document-plus": {
				background: "transparent url('/icon/document-plus.png') 0% 0% no-repeat padding-box",
			}
		},
		"& .icon-bg.orange": {
			background: "#FFEACC 0% 0% no-repeat padding-box",
		},
		"& .icon-bg.red": {
			background: "#FF5800 0% 0% no-repeat padding-box",
		},
		"& .icon-bg.blue": {
			background: "#CCFBFB 0% 0% no-repeat padding-box",
		},
		"& .icon-bg.green": {
			background: "#CDF7E7 0% 0% no-repeat padding-box",
		},
		"& .icon-bg.grey": {
			background: "#DEE4E9 0% 0% no-repeat padding-box",
		},
	}),
});

export default function StatusIcon(props) {
	const classes = useStyles(props);
	let statusIcon, iconBg;

	switch (props.status)
	{
		case 'Requested':
			statusIcon = 'icon document-check-orange';
			iconBg = "icon-bg orange";
			break;
		case 'In Progress':
			statusIcon = 'icon clock-blue';
			iconBg = "icon-bg blue";
			break;
		case 'Finished':
			statusIcon = 'icon circle-check-green';
			iconBg = "icon-bg green";
			break;
		case 'Paid':
			statusIcon = 'icon price-green';
			iconBg = "icon-bg green";
			break;
		case 'Overdue':
			statusIcon = 'icon price-red';
			iconBg = "icon-bg orange";
			break;
		case 'Draft':
		default:
			statusIcon = 'icon document-plus';
			iconBg = "icon-bg grey";
	}

	return (
		<div className={classes.root}>
			<div className={iconBg}>
				<div className={statusIcon}> </div>
			</div>
		</div>
	);
}