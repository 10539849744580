import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		display: "inline-block",
		height: 25,
		width: 40,
		backgroundColor: '#EFEFEF',
		marginLeft: 8
	}
});

export default function Flag(props) {
	const classes = useStyles();

	const url = new URL(document.location.href);
	url.searchParams.set('l10n', props.l10n);

	return (
		<a className={clsx([classes.root, props.l10n, props.current ? 'flag flag-current' : 'flag'])} href={url.toString()} title={_('common.' + props.l10n.split('-')[0])}> </a>
	);
}