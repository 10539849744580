import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {commonUtils_GetApiBaseUrl} from "../utils/common.utils";
import {layoutUtils_AdjustResourceImages} from "../utils/layout.utils";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: 88,
		padding: 0,
		boxSizing: "border-box",
		textAlign: "center",
		overflow: "hidden",
		position: "relative",
		"& img": {
			width: "80%",
			display: "block",
			position: "absolute",
			left: 0,
			top: "50%"
		},
		"& .no-opacity": {
			opacity: 1.0
		},
		"& .opacity": {
			opacity: 0.2
		}
	}
});

export default function ResourceImage(props) {
	const classes = useStyles(props);

	const resourceUrl = props.resource ? commonUtils_GetApiBaseUrl() + "/" + props.resource.resource.value.filePath : "/icon/image-128x128.png";
	const imgClass = (props.resource ? "no-opacity" : "opacity") + " resource-image";

	setTimeout(function (){
		layoutUtils_AdjustResourceImages();
	}, 300);

	return (
		<div className={classes.root}>
			<img src={resourceUrl} className={imgClass}/>
		</div>
	);
}