import {
    commonUtils_Capitalize, commonUtils_GetApiCallOptions,
    commonUtils_GetApiEndpoint,
    commonUtils_GetLocalization
} from "./common.utils";
import {layoutUtils_LoadingEnd} from "./layout.utils";

let l10n_Messages = null;
const l10n_UsedMessages = {};

export function l10n_GetMessages()
{
    if (l10n_Messages)
    {
        console.log("cached");
        return l10n_Messages;
    }
    else
    {
        const k = 'l10n_' + commonUtils_GetLocalization();
        const m = localStorage.getItem(k);

        if (m)
        {
            l10n_Messages = JSON.parse(m);
            console.log("loaded");
            return l10n_Messages;
        }
    }

    l10n_PrintEditor();

    return {};
}

export function _(message, args)
{
    const parts = message.split(".");
    const group = parts[0];
    const key = parts[1];
    const msgs = l10n_GetMessages();

    if (msgs[group] && msgs[group][key] && msgs[group][key][0])
    {
        let plurality = l10n_GetPlurality(args);

        while (plurality > -1)
        {
            if (msgs[group][key][plurality])
            {
                l10n_UsedMessages[message] = msgs[group][key];

                return l10n_Sprintf(msgs[group][key][plurality], args);
            }

            plurality -= 1;
        }
    }

    l10n_UsedMessages[message] = [];

    return message;
}

export function l10n_NormalizeKey(str)
{
    return str.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trim()
        .replace(/\s+/, '_');
}

function l10n_GetPlurality(args)
{
    if (args && args['*'])
    {
        const num = parseInt(args[args['*']]);

        return isNaN(num) ? 0 : (num > 4 ? 2 : (num > 1 ? 1 : 0));
    }

    return 0;
}

function l10n_Sprintf(mask, args)
{
    if (args)
    {
        for (let key in args)
        {
            mask = mask.replace('%' + key + '%', l10n_GetFormattedArg(key, args[key]));
        }

        return  args["^"] ? commonUtils_Capitalize(mask) : mask;
    }

    return mask;
}

function l10n_GetFormattedArg(key, value)
{
    return value;
}

export function l10n_PrintEditor(onlyHeader)
{
    if (document.location.host.match(/^(rc|dev)\./) === null) return;

    onlyHeader = onlyHeader || false;

    const container = document.querySelector('.l10n-translator') || document.createElement('div');
    container.className = 'l10n-translator';
    container.style.position = 'absolute';
    container.style.top = '0';
    container.style.left = '0';
    container.style.backgroundColor = '#c4d7ed';
    container.style.opacity = '.9';
    container.style.overflowX = 'hidden';
    container.style.zIndex = '3';
    container.innerHTML = "";

    const header = document.createElement('div');
    header.style.padding = '8px 16px';
    header.style.backgroundColor = '#a6c0dd';
    header.style.minWidth = '250px';
    header.style.backgroundRepeat = 'no-repeat';
    header.style.backgroundPosition = '70% 2px';
    header.className = commonUtils_GetLocalization();
    container.appendChild(header);

    const showHideBtn = document.createElement('a');
    showHideBtn.innerText = "<";
    showHideBtn.style.display = 'block';
    showHideBtn.style.position = 'absolute';
    showHideBtn.style.right = '0';
    showHideBtn.style.top = '10px';
    showHideBtn.style.cursor = 'pointer';
    showHideBtn.style.padding = '8px';
    showHideBtn.style.backgroundColor = '#8daac9';
    showHideBtn.className = 'l10n-close';
    showHideBtn.addEventListener('click', function (e){
       e.preventDefault();

       if (e.target.className === 'l10n-close')
       {
           e.target.parentNode.parentNode.style.width = '26px';
           e.target.innerText = ">";
           e.target.className = "l10n-open";
       }
       else
       {
           e.target.parentNode.parentNode.style.width = 'auto';
           e.target.innerText = "<";
           e.target.className = "l10n-close";
       }
    });

    header.appendChild(showHideBtn);

    const title = document.createElement('h3');
    title.innerText = "L10n editor";
    title.style.margin = '0';
    title.style.padding = '0';
    header.appendChild(title);

    const search = document.createElement('input');
    search.placeholder = 'search';
    search.addEventListener('keyup', function (e){
        const phrases = document.querySelectorAll('.l10n-phrase-container');
        const query = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        for (let i in phrases)
        {
            if (phrases.hasOwnProperty(i))
            {
                if (phrases[i].dataset.text.indexOf(query) > -1)
                {
                    phrases[i].style.display = 'block';
                }
                else
                {
                    phrases[i].style.display = 'none';
                }
            }
        }
    });
    header.appendChild(search);

    const content = document.createElement('div');
    content.style.padding = '0px 16px 8px 16px';
    content.style.fontWeight = 'normal';
    content.style.fontSize = '13px';
    container.appendChild(content);

    const reloadBtn = document.createElement('button');
    reloadBtn.innerText = 'Load phrases';
    reloadBtn.style.display = 'inline-block';
    reloadBtn.style.float = 'left';
    reloadBtn.style.marginTop = '16px';
    reloadBtn.style.marginRight = '16px';
    reloadBtn.addEventListener('click', function (e){
       e.preventDefault();
       l10n_PrintEditor();
    });

    content.appendChild(reloadBtn);

    if (!onlyHeader) {
        const saveBtn = document.createElement('button');
        saveBtn.innerText = 'Save all changes';
        saveBtn.style.display = 'inline-block';
        saveBtn.style.float = 'right';
        saveBtn.style.marginTop = '16px';
        saveBtn.addEventListener('click', function (e) {
            e.preventDefault();

            const phrases = document.querySelectorAll('.l10n-phrase-container');
            const dict = {};

            for (let i in phrases) {
                if (phrases.hasOwnProperty(i)) {
                    let message = phrases[i].querySelector('h4').innerText;
                    let inputs = phrases[i].querySelectorAll('input');
                    let parts = message.split('.');

                    dict[parts[0]] = dict[parts[0]] || {};
                    dict[parts[0]][parts[1]] = [];

                    for (let j in inputs) {
                        if (inputs.hasOwnProperty(j)) {
                            dict[parts[0]][parts[1]].push(inputs[j].value);
                        }
                    }
                }
            }

            fetch(
                commonUtils_GetApiEndpoint(process.env.REACT_APP_API_SAVE_L10N),
                commonUtils_GetApiCallOptions({
                    data: dict,
                    l10n: commonUtils_GetLocalization()
                })
            )
                .then(blob => blob.json())
                .then(response => {
                    layoutUtils_LoadingEnd();

                    if (response.statusCode === 200) {
                        localStorage.setItem('l10n_' + commonUtils_GetLocalization(), JSON.stringify(response.data));
                        document.location.reload();
                    }
                })
                .catch(e => {
                    console.log(e);
                    layoutUtils_LoadingEnd();
                    return e;
                });
        });

        content.appendChild(saveBtn);
    }

    const hr = document.createElement('hr');
    hr.style.clear = 'both';
    hr.style.display = 'block';
    hr.style.paddingTop = '16px';
    hr.style.border = 'none';
    hr.style.borderBottom = '1px solid #a6c0dd';

    content.appendChild(hr);

    if (!onlyHeader) {
        for (let i in l10n_UsedMessages) {
            if (l10n_UsedMessages.hasOwnProperty(i)) {
                let phraseContainer = document.createElement('div');
                phraseContainer.className = 'l10n-phrase-container';
                phraseContainer.style.borderBottom = '1px solid #a6c0dd';
                phraseContainer.style.paddingBottom = '16px';
                phraseContainer.style.clear = 'both';
                phraseContainer.setAttribute("data-text", i + ' ' + l10n_UsedMessages[i].join(' ').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                content.appendChild(phraseContainer);

                let message = document.createElement('h4');
                message.innerText = i;
                message.style.marginTop = '16px';
                message.style.marginBottom = '8px';
                phraseContainer.appendChild(message);

                for (let j = 0; j < 3; j++) {
                    let label = document.createElement('h5');
                    label.innerText = (j === 0 ? 'singular' : ('plural ' + j)) + ':';
                    label.style.margin = '0';
                    label.style.marginTop = '8px';
                    label.style.marginBottom = '4px';
                    phraseContainer.appendChild(label);

                    let input = document.createElement('input');
                    input.style.display = "block";
                    input.style.width = "280px";
                    input.value = l10n_UsedMessages[i][j] ?? '';
                    phraseContainer.appendChild(input);
                }
            }
        }
    }

    document.body.appendChild(container);
}