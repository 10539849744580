import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExampleTagCloud7NoCleaning from "./ExampleTagCloud7NoCleaning";
import ExampleTagCloud7PhrasesSimilarity from "./ExampleTagCloud7PhrasesSimilarity";
import ExampleTagCloud22NoCleaning from "./ExampleTagCloud22NoCleaning";
import ExampleTagCloud22PhrasesSimilarity from "./ExampleTagCloud22PhrasesSimilarity";

const useStyles = makeStyles({
	root: {
		backgroundColor: "#fff",
		padding: 32,
		"& ul": {
			margin: 0,
			padding: 0,
			"& li": {
				display: "inline-block",
				margin: 0,
				padding: 0,
				marginRight: 24
			}
		}
	}
});

export default function ExamplePage(props) {
	const classes = useStyles();

	const url = new URL(document.location.href);

	const components = {
		ExampleTagCloud7NoCleaning: ExampleTagCloud7NoCleaning,
		ExampleTagCloud7PhrasesSimilarity: ExampleTagCloud7PhrasesSimilarity,
		ExampleTagCloud22NoCleaning: ExampleTagCloud22NoCleaning,
		ExampleTagCloud22PhrasesSimilarity: ExampleTagCloud22PhrasesSimilarity
	}

	let ExampleComponent;

	if (url.searchParams.has('e'))
	{
		ExampleComponent = components[url.searchParams.get('e')];
	}

	return (
		<div className={classes.root}>
			<ul>
				<li><a href="?e=ExampleTagCloud7NoCleaning">Bez čištění (qid = 7)</a></li>
				<li><a href="?e=ExampleTagCloud7PhrasesSimilarity">Podobnost frází (qid = 7)</a></li>
				<li><a href="?e=ExampleTagCloud22NoCleaning">Bez čištění (qid = 22)</a></li>
				<li><a href="?e=ExampleTagCloud22PhrasesSimilarity">Podobnost frází (qid = 22)</a></li>
			</ul>
			{ExampleComponent && <ExampleComponent/>}
		</div>
	);
}
