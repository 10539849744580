import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Flag from "./Flag";
import {commonUtils_GetLocalization} from "../utils/common.utils";

const useStyles = makeStyles({
	root: {

	}
});

export default function ProfileFlags(props) {
	const classes = useStyles();
	const current = commonUtils_GetLocalization();

	return (
		<div className={classes.root}>
			<Flag l10n="cs-CZ" current={current === 'cs-CZ'}/>
			<Flag l10n="en-US" current={current === 'en-US'}/>
			<Flag l10n="sk-SK" current={current === 'sk-SK'}/>
		</div>
	);
}