import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "../Logo";
import ProfileFlags from "../profile/ProfileFlags";

const useStyles = makeStyles({
	h1: {
		borderBottom: "1px solid #DBE5EE",
		marginTop: 24,
		marginBottom: 29,
		paddingBottom: 24
	},
	logo: {
		paddingLeft: 101
	},
	flags: {
		marginTop: 24,
		"& div": {
			paddingLeft: 73
		}
	}
});

export default function AuthHeader(props) {
	const classes = useStyles();

	return (
		<Box className="sign-form-header">
			<div className={classes.logo}>
				<Logo width="118px" margin="0 auto"/>
				<div className="clearfix"> </div>
			</div>
			{props.showFlags && <div className={classes.flags}><ProfileFlags/></div>}
			<h1 className={classes.h1}>{props.content}</h1>
		</Box>
	);
}