import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: props => ({
		margin: props.margin,
		height: 4,
		width: "100%",
		backgroundColor: "#DBE5EE",
		position: "relative",
		borderRadius: 4,
		"& .completed": {
			backgroundColor: props.color,
			width: props.finished + "%",
			height: 4,
			borderRadius: 4,
		},
		"& .label": {
			color: props.color,
			marginTop: 8,
			textAlign: "right"
		}
	})
});

export default function BlockLabels(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className="completed"> </div>
			<div className="label">{props.label}</div>
		</div>
	);
}