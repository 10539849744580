import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {researchUtils_GetResources} from "../../utils/research.utils";
import Result_Question_Resource from "./Result_Question_Resource";
import {commonUtils_AddClass, commonUtils_HasClass, commonUtils_RemoveClass} from "../../utils/common.utils";
import {_} from "../../utils/l10n";

const useStyles = makeStyles({
	root: {
		padding: "16px 32px",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: "100%",
		boxSizing: "border-box",
		float: "left",
		lineHeight: 1,
		marginTop: 16,
		"& .question": {
			width: 935,
			float: "left",
			font: "normal normal normal 16px/25px 'Montserrat'",
			letterSpacing: 0,
			color: "#000000"
		},
		"& .tools": {
			width: 100,
			float: "right",
			"& a": {
				display: "inline-block",
				width: 24,
				height: 24,
				float: "right",
				marginLeft: 16
			},
			"& .open": {
				background: "transparent url('/icon/circle-down.png') 0% 0% no-repeat padding-box"
			},
			"& .close": {
				background: "transparent url('/icon/circle-top.png') 0% 0% no-repeat padding-box"
			}
		},
		"& .detail": {
			width: "100%",
			float: "left",
			marginBottom: 16,
			paddingTop: 24
		}
	},
	result: {
		width: "100%",
		boxSizing: "border-box",
		borderTop: "1px solid #DBE5EE",
		float: "left",
		marginTop: 16,
		marginBottom: 8
	},
	detailLink: {
		width: "100%",
		float: "left",
		textAlign: "center",
		marginBottom: 16,
		"& a": {
			textDecoration: "underline",
			font: "normal normal medium 15px/23px Raleway",
			color: "#5C7692"
		}
	}
});

export default function Result_Question(props) {
	const classes = useStyles(props);

	let i;
	const resources = researchUtils_GetResources(props.research);
	const resourceBlocks = [];

	for (i in resources)
	{
		if (resources.hasOwnProperty(i))
		{
			resourceBlocks.push(
				<Result_Question_Resource
					key={i}
					name={resources[i].value.name}
					resourceId={resources[i].value.id}
					questionId={props.question.id.value}
					marginLeft={i > 0 ? 32 : 0}
				/>
			);
		}
	}

	function handleOpenClose(e)
	{
		e.preventDefault();

		if (e.target.className === 'detail-link')
		{
			e.target.parentNode.parentNode.querySelector(".tools a").click();
		}
		else
		{
			const el = e.target.parentNode.parentNode.querySelector(".detail");
			const link = e.target.parentNode.parentNode.querySelector(".detail-link");
			const cl = "display-none";

			if (e.target.dataset.opened === "1")
			{
				e.target.className = "open";
				e.target.dataset.opened = "0";
				link.innerText = _('common.show_more_details');

				if (!commonUtils_HasClass(el, cl))
				{
					commonUtils_AddClass(el, cl);
				}
			}
			else
			{
				e.target.className = "close";
				e.target.dataset.opened = "1";
				link.innerText = _('common.close_more_details');

				if (commonUtils_HasClass(el, cl))
				{
					commonUtils_RemoveClass(el, cl);
				}
			}
		}
	}

	return (
		<Card className={classes.root + " question-result-block"}>
			<div className="question">
				{props.question.orderTitle.value}
			</div>
			<div className="tools">
				<a href="#" className={props.opened ? "close" : "open"} data-opened={props.opened ? "1" : "0"} onClick={handleOpenClose}> </a>
			</div>
			<div className={classes.result}>{resourceBlocks}</div>
			<div className={classes.detailLink}><a href="#" className="detail-link" onClick={handleOpenClose}>{_('common.show_more_details')}</a></div>
			<div className="detail display-none" id={"QRD_" + props.question.id.value}> </div>
		</Card>
	);
}