import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Checkbox} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	chckIcon: {
		background: "#DBE5EE 0% 0% no-repeat padding-box",
		borderRadius: 4,
		width: 24,
		height: 24
	},
	chckIconChecked: {
		background: "#0162CA 0% 0% no-repeat padding-box",
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
	},
});

export default function CustomCheckbox(props) {
	const classes = useStyles(props);

	return (
		<Checkbox
			className={classes.root}
			disableRipple
			color="default"
			icon={<span className={classes.chckIcon} />}
			checkedIcon={<span className={clsx(classes.chckIcon, classes.chckIconChecked)} />}
			{...props}
		/>
	);
}
