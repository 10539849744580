import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {commonUtils_HomepageUrl, commonUtils_MeasurementUrl} from "./utils/common.utils";

const useStyles = makeStyles({
	root: props => ({
		display: "block",
		width: props.width,
		margin: props.margin,
		float: "left",
		"& img": {
			width: "100%"
		}
	})
});

export default function Logo(props) {
	const classes = useStyles(props);

	return (
		<a className={classes.root} href={props.measurement ? commonUtils_MeasurementUrl(props.measurement.id) : commonUtils_HomepageUrl()}>
			<img src="/h24-logo.png" alt="Logo H24"/>
		</a>
	);
}