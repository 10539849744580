import React from "react";

export function generateQuestionName(props)
{
	let questionName = props.question.titleMask.value;

	if (props.brandIndex)
	{
		questionName = questionName.replace('{{App.Model.Research.Brand.name}}', props.research.brands.value[props.brandIndex].value.name);
	}

	if (props.productIndex)
	{
		questionName = questionName.replace('{{App.Model.Research.Product.name}}', props.research.products.value[props.productIndex].value.name);
		questionName = questionName.replace('{{App.Model.Research.Product.price}}', props.research.products.value[props.productIndex].value.price);
		questionName = questionName.replace('{{App.Model.Research.Product.currency}}', props.research.products.value[props.productIndex].value.currency);
	}

	return questionName;
}