import React, {useState} from "react";
import Header from "./header/Header";
import BlockVideo from "./BlockVideo";
import ResearchDefinition from "./research/ResearchDefinition";
import ResearchSummary from "./research/ResearchSummary";
import MeasurementIntegration from "./measurement/MeasurementIntegration";
import WaitingForRespondents from "./measurement/WaitingForRespondents";
import NewResearch from "./research/NewResearch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResearchPayment from "./research/ResearchPayment";
import Result_Research from "./research/result/Result_Research";
import {Alert} from "@material-ui/lab";
import {
    commonUtils_CheckAuthorized,
    commonUtils_GetApiCallOptions,
    commonUtils_GetApiEndpoint,
    commonUtils_GetClient,
    commonUtils_GetCountry,
    commonUtils_GetMeasurement,
    commonUtils_GetResearch,
    commonUtils_GetUrlState,
    commonUtils_GetUser,
    commonUtils_MeasurementUrl,
    commonUtils_PaymentUrl,
    commonUtils_RedirectTo,
    commonUtils_SetData
} from "./utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "./utils/layout.utils";
import UserProfile from "./profile/UserProfile";

const useStyles = makeStyles({
    root: {
        width: 1200,
        float: "left",
        "& .backdrop": {
            color: "#FFFFFF",
            zIndex: 10
        }
    },
    alert: {
        borderRadius: 24,
        marginTop: 32
    }
});

export default function PrivatePage(props) {

    const classes = useStyles(props);

    // region state definition

    const [isLoading, setIsLoading] = useState(false);
    const [render, forceRender] = useState(0);
    const [isUploadSuccess, setIsUploadSuccess] = useState(true);
    const [uploaderMessage, setUploaderMessage] = useState("");

    // endregion

    // region startup

    const user = commonUtils_GetUser();
    const country = commonUtils_GetCountry();

    const client = commonUtils_GetClient();
    if (!client) return null;

    const urlState = commonUtils_GetUrlState();
    const mid = urlState.measurement || "0"; // measurement id
    const rid = urlState["new-research"] || urlState["research"] || "0"; // research id

    const measurement = commonUtils_GetMeasurement();
    const research = commonUtils_GetResearch();

    const activeRespondents = measurement ? measurement.activeRespondents[country.code] || 0 : 0;

    if (rid !== "0" && !isResearchDefinition() && !isSummary() && !isPayment() && !isDetail())
    {
        commonUtils_RedirectTo(commonUtils_MeasurementUrl(mid));
        return;
    }

    const url = new URL(window.location.href);

    if (rid === "0" && mid === "0" && !url.searchParams.has('codes') && !isProfile())
    {
        if (client.isMeasurementImplemented)
        {
            let goToMid, maxRespondents = -1;

            for (let i in client.measurements)
            {
                if (client.measurements.hasOwnProperty(i))
                {
                    if (maxRespondents < client.measurements[i].value.activeRespondents[country.code])
                    {
                        maxRespondents = client.measurements[i].value.activeRespondents[country.code];
                        goToMid = client.measurements[i].value.id;
                    }
                }
            }

            commonUtils_RedirectTo(commonUtils_MeasurementUrl(goToMid));
            return;
        }
    }

    // endregion

    // region steps

    function isProfile()
    {
        return window.location.pathname === '/profile';
    }

    function isIntegration()
    {
        return !isProfile() && !measurement // no mid in URL
            || !(client.isMeasurementImplemented || false); // measurement not set as implemented on the client
    }

    function isWaitingForRespondents()
    {
        return !isIntegration() && !isDetail()
            && (measurement && activeRespondents < measurement.minRespondents); // too few respondents
    }

    function isResearchDefinition()
    {
        return  !isWaitingForRespondents()
            && research // rid is in URL (new-research) and research exists
            && !urlState.summary
            && !urlState.payment
            && !urlState.research;
    }

    function isDashboard()
    {
        return !isResearchDefinition() && !urlState.summary && !urlState.payment&& !urlState.research
            && (measurement && measurement.activeRespondents[country.code] >= measurement.minRespondents); // number of respondents is ok
    }

    function isSummary()
    {
        return !isIntegration() && !isWaitingForRespondents() && !isResearchDefinition() && !isDashboard() && urlState.summary && !urlState.payment;
    }

    function isShowVideoEnabled()
    {
        return (isWaitingForRespondents() || isDashboard()) && measurement.numResearches < 1;
    }

    function isPayment()
    {
        return !isIntegration() && !isWaitingForRespondents() && !isResearchDefinition() && !isDashboard() && !urlState.summary && urlState.payment;
    }

    function isDetail()
    {
        return !isIntegration() && urlState.research;
    }

    // endregion

    // region handlers

    function handleNumRespondentsChange(event)
    {
        event.preventDefault();

        if (!isLoading && research.paneluids.value !== parseInt(event.target.dataset.value)) {
            setIsLoading(true);
            layoutUtils_LoadingStart();

            fetch(
                commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_SET),
                commonUtils_GetApiCallOptions({
                uid: user.id,
                token: user.token,
                co: country.code,
                cid: client.id,
                mid: mid,
                rid: research.id.value,
                key: "paneluids",
                value: event.target.dataset.value
            }))
            .then(blob => blob.json())
            .then(response => {
                if (commonUtils_CheckAuthorized(response))
                {
                    if (response.statusCode === 200)
                    {
                        commonUtils_SetData(response.data);
                        forceRender(render => ++render);
                    }
                    else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
                    {
                        commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
                    }
                }

                setIsLoading(false);
                layoutUtils_LoadingEnd();
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                layoutUtils_LoadingEnd();
                return e;
            });
        }
    }

    function handleAddQuestionSelectionChange(event, qid)
    {
        if (!isLoading) setIsLoading(true); else return;

        layoutUtils_LoadingStart();

        const key = event.target.checked ? 'addQuestion' : 'rmQuestion';

        fetch(
            commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_SET),
            commonUtils_GetApiCallOptions({
                uid: user.id,
                token: user.token,
                co: country.code,
                cid: client.id,
                mid: mid,
                rid: research.id.value,
                key: key,
                value: qid
            })
        )
        .then(blob => blob.json())
        .then(response => {
            if (commonUtils_CheckAuthorized(response))
            {
                if (response.statusCode === 200)
                {
                    commonUtils_SetData(response.data);
                    forceRender(render => ++render);
                }
                else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
                {
                    commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
                }
            }

            setIsLoading(false);
            layoutUtils_LoadingEnd();
        })
        .catch(e => {
            console.log(e);
            setIsLoading(false);
            layoutUtils_LoadingEnd();
            return e;
        });
    }

    function handleResourceChange(event, elemId)
    {
        let name = document.getElementById(elemId + "name");
        let price;

        if (elemId.match('^product'))
        {
            price = document.getElementById(elemId + "price");

            if (price.value.trim() === '')
            {
                return;
            }
        }

        if (name.value.trim() === '')
        {
            return;
        }

        if (!isLoading && research.paneluids.value !== parseInt(event.target.dataset.value)) {
            setIsLoading(true);
            layoutUtils_LoadingStart();

            fetch(
                commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_SET),
                commonUtils_GetApiCallOptions({
                    uid: user.id,
                    token: user.token,
                    co: country.code,
                    cid: client.id,
                    mid: mid,
                    rid: research.id.value,
                    key: elemId,
                    value: {
                        name: name.value,
                        price: typeof price === 'object' ? price.value : null
                    }
                })
            )
            .then(blob => blob.json())
            .then(response => {
                if (commonUtils_CheckAuthorized(response))
                {
                    if (response.statusCode === 200)
                    {
                        commonUtils_SetData(response.data);
                        forceRender(render => ++render);
                    }
                    else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
                    {
                        commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
                    }
                }

                setIsLoading(false);
                layoutUtils_LoadingEnd();
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                layoutUtils_LoadingEnd();
                return e;
            });
        }
    }

    function handleFileSelected(e, resourceIndex, callback)
    {
        if (!isLoading) setIsLoading(true); else return;
        layoutUtils_LoadingStart();

        const files = e.target.files;
        const formData = new FormData;

        formData.append('resourceFile', files[0]);
        formData.append('uid', user.id);
        formData.append('token', user.token);
        formData.append('co', country.code);
        formData.append('cid', client.id);
        formData.append('mid', measurement.id);
        formData.append('rid', research.id.value);
        formData.append('rin', resourceIndex);

        fetch(
            commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_UPLOAD_RESOURCE),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                },
                credentials: 'include',
                body: formData
            }
        )
        .then(blob => blob.json())
        .then(response => {
            if (commonUtils_CheckAuthorized(response))
            {
                if (response.statusCode === 200)
                {
                    commonUtils_SetData(response.data);
                    callback();
                    setUploaderMessage("");
                    setIsUploadSuccess(true);
                }
                else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
                {
                    commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
                }
                else
                {
                    setUploaderMessage(response.data.detail);
                    setIsUploadSuccess(false);
                }
            }

            setIsLoading(false);
            layoutUtils_LoadingEnd();
        })
        .catch(e => {
            console.log(e);
            setIsLoading(false);
            layoutUtils_LoadingEnd();
            return e;
        });
    }

    function handleCheckout(e, companyName, billingAddress, cin, vatNumber, email, phone)
    {
        if (!isLoading) setIsLoading(true); else return;
        layoutUtils_LoadingStart();

        fetch(
            commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_CHECKOUT),
            commonUtils_GetApiCallOptions({
                uid: user.id,
                token: user.token,
                co: country.code,
                cid: client.id,
                mid: mid,
                rid: rid,
                billing: {
                    company: companyName,
                    billingAddress: billingAddress,
                    cin: cin,
                    vatNumber: vatNumber,
                    email: email,
                    phone: phone
                }
            })
        )
        .then(blob => blob.json())
        .then(response => {
            if (commonUtils_CheckAuthorized(response))
            {
                if (response.statusCode === 200)
                {
                    commonUtils_RedirectTo(commonUtils_PaymentUrl(mid, rid));
                }
                else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
                {
                    commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
                }
            }

            setIsLoading(false);
            layoutUtils_LoadingEnd();
        })
        .catch(e => {
            console.log(e);
            setIsLoading(false);
            layoutUtils_LoadingEnd();
            return e;
        });
    }

    // endregion

    return (
        <React.Fragment>
            <Header cid={client.id}
                    measurement={measurement}
                    research={research}
                    isIntegration={isIntegration}
                    isWaitingForRespondents={isWaitingForRespondents}
                    isDashboard={isDashboard}
                    isResearchDefinition={isResearchDefinition}
                    isSummary={isSummary}
                    isPayment={isPayment}
                    isDetail={isDetail}
                    isProfile={isProfile}/>

            <div id="content" className={classes.root}>

                {!isDetail() && research && !research.isValid && <Alert severity="info" className={classes.alert}>{research.errorMessage}</Alert>}
                {!isUploadSuccess && <Alert severity="warning" className={classes.alert}>{uploaderMessage}</Alert>}

                {isIntegration() && <MeasurementIntegration client={client}/>}
                {isWaitingForRespondents() && <WaitingForRespondents client={client} measurement={measurement}/>}
                {isDashboard() && <NewResearch client={client} measurement={measurement}/>}
                {isShowVideoEnabled() && <BlockVideo/>}
                {isResearchDefinition()
                    && <ResearchDefinition
                            client={client}
                            measurement={measurement}
                            research={research}
                            handleNumRespondentsChange={handleNumRespondentsChange}
                            handleFileSelected={handleFileSelected}
                            handleAddQuestionSelectionChange={handleAddQuestionSelectionChange}
                            handleResourceChange={handleResourceChange}
                            setIsLoading={setIsLoading}
                />}
                {isDetail() && <Result_Research client={client}
                                                measurement={measurement}
                                                research={research}/>}
                {isSummary() && <ResearchSummary client={client} measurement={measurement} research={research} handleCheckout={handleCheckout}/>}
                {isPayment() && <ResearchPayment  client={client}
                                                  measurement={measurement}
                                                  research={research}/>}
                {isProfile() && <UserProfile/>}
            </div>
        </React.Fragment>
    );
}