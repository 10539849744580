import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
    commonUtils_GetLocalization,
    commonUtils_HomepageUrl,
    commonUtils_LoadData,
    commonUtils_Ping
} from "./utils/common.utils";
import {Alert} from "@material-ui/lab";
import {
    layoutUtils_SetupDynamicHeaderSize,
    layoutUtils_SetupDynamicModalHeight
} from "./utils/layout.utils";
import {_, l10n_PrintEditor} from "./utils/l10n";


const url = new URL(document.location.href);
const l = localStorage.getItem('l10n');
if (!url.searchParams.has('l10n') && l !== null)
{
    url.searchParams.set('l10n', l);
    document.location = url.toString();
}
else
{
    localStorage.setItem('l10n', commonUtils_GetLocalization());

    commonUtils_Ping(function (){
        commonUtils_LoadData(function(response){
            const el = document.getElementById('root');

            if (response.statusCode !== 403)
            {
                ReactDOM.render(<App />, el);
                layoutUtils_SetupDynamicHeaderSize();
                layoutUtils_SetupDynamicModalHeight();
            }
            else
            {
                ReactDOM.render(
                    <Alert severity="warning">
                        {response.data.detail}
                        {_('common.go_to')}
                        <a href={commonUtils_HomepageUrl()}>{_('common.dashboard')}</a>.
                    </Alert>, el);
            }
        });
    });

    l10n_PrintEditor(true);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
