import React, {useState} from "react";
import BlockLabels from "../BlockLabels";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
	commonUtils_CheckAuthorized,
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint, commonUtils_GetClient, commonUtils_GetCountry,
	commonUtils_GetUser, commonUtils_MeasurementId,
	commonUtils_NumberFormat, commonUtils_ResearchId
} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		"& .recapitulation": {
			padding: 40,
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			boxShadow: "0px 8px 24px #114E9114",
			borderRadius: 24,
			width: 768,
			boxSizing: "border-box",
			float: "left",
			marginRight: 32,
			"& .header": {
				paddingBottom: 16,
				borderBottom: "1px solid #DBE5EE",
				marginBottom: 24,
				position: "relative",
				"& .icon-bg": {
					borderRadius: 8,
					float: "left",
					marginRight: 16,
					"& .icon": {
						width: 24,
						height: 24,
						margin: 12,
					}
				},
				"& .icon-bg.grey": {
					background: "#DEE4E9 0% 0% no-repeat padding-box",
					"& .icon": {
						background: "transparent url('/icon/graph-bluegrey.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .icon-bg.green": {
					background: "#CDF7E7 0% 0% no-repeat padding-box",
					"& .icon": {
						background: "transparent url('/icon/face-smile-green.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .icon-bg.price-green": {
					background: "#CDF7E7 0% 0% no-repeat padding-box",
					"& .icon": {
						background: "transparent url('/icon/price-green.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .icon-bg.blue": {
					background: "#CCFBFB 0% 0% no-repeat padding-box",
					"& .icon": {
						background: "transparent url('/icon/message-blue.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .icon-bg.orange": {
					background: "#FFEACC 0% 0% no-repeat padding-box",
					"& .icon": {
						background: "transparent url('/icon/price-orange.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .heading": {
					paddingTop: 15,
					width: 300,
					float: "left",
					"& h3": {
						font: "normal normal normal 16px/19px Montserrat",
						color: "#000000",
						margin: 0,
						padding: 0
					}
				},
				"& .quantity": {
					width: 320,
					float: "left",
					textAlign: "right",
					"& h2": {
						display: "inline-block",
						font: "normal normal bold 16px/25px Montserrat",
						color: "#000000"
					},
					"& span": {
						font: "normal normal normal 13px/20px Montserrat",
						color: "#627A97"
					}
				},
				"& .quantity.total-price": {
					"& h2": {
						font: "normal normal bold 25px/35px Montserrat",
						margin: 0,
						marginTop: 10
					},
					"& em": {
						font: "normal normal normal 13px/20px Montserrat",
						fontStyle: "oblique",
						display: "block",
						color: "#627A97"
					}
				},
				"& .quantity.discount": {
					"& h2": {
						font: "normal normal bold 16px/25px Montserrat",
						margin: 0,
						marginTop: 10
					},
					"& em": {
						font: "normal normal normal 13px/20px Montserrat",
						fontStyle: "oblique",
						display: "block",
						color: "#627A97"
					},
					"& .btn-apply": {
						position: "absolute",
						top: 6,
						right: 10,
						background: "0% 0% no-repeat padding-box padding-box rgb(1, 98, 202)",
						boxShadow: "rgb(2 43 87 / 16%) 0px 4px 16px",
						borderRadius: 8,
						textTransform: "uppercase",
						"& .MuiButton-label": {
							color: "#fff",
						}
					}
				}
			},
		},
		"& .billing": {
			padding: 40,
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			boxShadow: "0px 8px 24px #114E9114",
			borderRadius: 24,
			width: 400,
			boxSizing: "border-box",
			float: "left",
			marginRight: 0,
			marginBottom: 64,
			"& .header": {
				paddingBottom: 32,
				borderBottom: "1px solid #DBE5EE",
				marginBottom: 24,
				"& .icon-bg": {
					background: "#FFEACC 0% 0% no-repeat padding-box",
					borderRadius: 8,
					float: "left",
					marginRight: 16,
					"& .icon": {
						width: 24,
						height: 24,
						margin: 12,
						background: "transparent url('/icon/price-orange.png') 0% 0% no-repeat padding-box",
					}
				},
				"& .heading": {
					"& h1": {
						margin: 0,
						height: 48,
						lineHeight: 2
					}
				}
			},
			"& form": {
				"& .MuiFormControl-root": {
					marginBottom: 32
				},
				"& button": {
					background: "#0162CA 0% 0% no-repeat padding-box",
					boxShadow: "0px 4px 16px #022B5729",
					borderRadius: 8,
					padding: "16px 32px",
					font: "normal normal normal 14px/18px Montserrat",
					color: "#FFFFFF",
					textTransform: "uppercase"
				}
			}
		}
	}
});

export default function ResearchSummary(props) {
	const classes = useStyles(props);

	const [companyName, setCompanyName] = useState("");
	const [billingAddress, setBillingAddress] = useState("");
	const [cin, setCin] = useState("");
	const [vatId, setVatId] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [discountCode, setDiscountCode] = useState("");
	const [discountCodeMsg, setDiscountCodeMsg] = useState("");

	const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
	const [isBillingAddressValid, setIsBillingAddressValid] = useState(true);
	const [isCinValid, setIsCinValid] = useState(true);
	const [isVatIdValid, setIsVatIdValid] = useState(true);
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(true);

	const country = commonUtils_GetCountry();

	const objToType = {
		"App.Model.Research.ProductResearch": _('research.product'),
		"App.Model.Research.BrandResearch": _('research.brand'),
	}

	const type = objToType[props.measurement.researches[props.research.id.value].type];

	function checkCompanyName()
	{
		const isValid = companyName.trim() !== '';

		setIsCompanyNameValid(isValid);

		return isValid;
	}

	function checkBillingAddress()
	{
		const isValid = billingAddress.trim() !== '';

		setIsBillingAddressValid(isValid);

		return isValid;
	}

	function checkCin()
	{
		const isValid = !isNaN(parseInt(cin.replace(/\s/, '')));

		setIsCinValid(isValid);

		return isValid;
	}

	function checkVatId()
	{
		const isValid = vatId.match(/^[A-Z]{2}[0-9]{8,10}$/) !== null;

		setIsVatIdValid(isValid);

		return isValid;
	}

	function checkPhone()
	{
		const isValid = phone.trim().replace(/\s/, '').length > 8;

		setIsPhoneValid(isValid);

		return isValid;
	}

	function checkEmail()
	{
		const isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

		setIsEmailValid(isValid);

		return isValid;
	}

	function isBillingDataValid()
	{
		return checkCompanyName()
			&& checkBillingAddress()
			&& checkCin()
			&& checkVatId()
			&& checkEmail()
			&& checkPhone();
	}

	function handleSubmit(event)
	{
		event.preventDefault();

		if (isBillingDataValid())
		{
			props.handleCheckout(event, companyName, billingAddress, cin, vatId, email, phone);
		}
	}

	function handleApplyDiscount(e)
	{
		e.preventDefault();

		if (discountCode.length === 6)
		{
			layoutUtils_LoadingStart();

			const user = commonUtils_GetUser();
			const country = commonUtils_GetCountry();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_APPLY_DISCOUNT),
				commonUtils_GetApiCallOptions({
					uid: user.id,
					token: user.token,
					co: country.code,
					cid: commonUtils_GetClient().id,
					mid: commonUtils_MeasurementId(),
					rid: commonUtils_ResearchId(),
					code: discountCode
				})
			)
			.then(blob => blob.json())
			.then(response => {
				if (commonUtils_CheckAuthorized(response)) {
					if (response.statusCode === 200) {
						document.location.reload();
					}
					else
					{
						setIsDiscountCodeValid(false);
						setDiscountCodeMsg(response.data.detail);
					}
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
		else
		{
			setDiscountCodeMsg(_('common.invalid_discount_code'));
			setIsDiscountCodeValid(false);
		}
	}

	function handleChangeDiscountCode(value)
	{
		setDiscountCode(value);
		setIsDiscountCodeValid(true);
	}

	return (
		<div className={classes.root}>
			<BlockLabels
				text1={_('research.summary')}
				text2={_('common.billing_information')}
				mr1="0"
				mr2="800"
				margin="32px 0 16px 32px"/>

			<Card className="recapitulation">
				<div className="header">
					<div className="icon-bg grey"><div className="icon"> </div></div>
					<div className="heading"><h3>{type}</h3></div>
					<div className="clearfix"> </div>
				</div>
				<div className="header">
					<div className="icon-bg green"><div className="icon"> </div></div>
					<div className="heading"><h3>{_('common.respondent', {num: 2, "*": "num"})}</h3></div>
					<div className="quantity">
						<h2>{props.research.paneluids.value}</h2>
						<span> /{props.measurement.activeRespondents[country.code]}</span>
					</div>
					<div className="clearfix"> </div>
				</div>
				<div className="header">
					<div className="icon-bg blue"><div className="icon"> </div></div>
					<div className="heading"><h3>{_('common.question', {num: 2, "*": "num"})}</h3></div>
					<div className="quantity">
						<h2>{props.research.selectedQuestionPatternIds.value.length}</h2>
						<span> /{props.research.questionPatterns.value.length}</span>
					</div>
					<div className="clearfix"> </div>
				</div>
				<div className="header">
					<div className="icon-bg price-green"><div className="icon"> </div></div>
					<div className="heading"><h3>{_('common.discount')}</h3></div>
					<div className="quantity discount">
						{props.research.discounts.value.length > 0 &&
							<React.Fragment>
								<h2>{commonUtils_NumberFormat(props.research.wholeDiscount.value)}&nbsp;{props.research.currency.value}</h2>
								<em>{_('common.without_vat')}</em>
							</React.Fragment>
						}
						{props.research.discounts.value.length === 0 &&
							<React.Fragment>
								<TextField id="discountCodeInput" label={_('common.discount_code')} error={!isDiscountCodeValid} helperText={isDiscountCodeValid ? '' : _('common.invalid_discount_code')} type="text" value={discountCode} onChange={e => handleChangeDiscountCode(e.target.value.trim())} variant="outlined" fullWidth={true} size="small"/>
								<Button variant="contained" color="primary" type="button" size="small" className="btn-apply" onClick={handleApplyDiscount}>{_('common.apply')}</Button>
							</React.Fragment>
						}
					</div>
					<div className="clearfix"> </div>
				</div>
				<div className="header">
					<div className="icon-bg orange"><div className="icon"> </div></div>
					<div className="heading"><h3>{_('common.total_price')}</h3></div>
					<div className="quantity total-price">
						<h2>{commonUtils_NumberFormat(props.research.totalPrice.value)}&nbsp;{props.research.currency.value}</h2>
						<em>{_('common.vat_xx_perc_included', {perc: props.research.vatPerc.value})}</em>
					</div>
					<div className="clearfix"> </div>
				</div>
			</Card>
			<Card className="billing">
				<div className="header">
					<div className="icon-bg">
						<div className="icon"> </div>
					</div>
					<div className="heading">
						<h1>{_('common.billing_data')}</h1>
					</div>
					<div className="clearfix"> </div>
				</div>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<TextField label={_('common.company_name')} error={!isCompanyNameValid} helperText={isCompanyNameValid ? '' : _('common.company_name_invalid_info')} type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} variant="outlined" fullWidth={true}/>
					<TextField label={_('common.billing_address')} error={!isBillingAddressValid} helperText={isBillingAddressValid ? '' : _('common.billing_address_invalid_info')} type="text" value={billingAddress} onChange={e => setBillingAddress(e.target.value)} variant="outlined" fullWidth={true}/>
					<TextField label={_('common.company_id')} error={!isCinValid} helperText={isCinValid ? '' : _('common.company_id_invalid_info')} type="text" value={cin} onChange={e => setCin(e.target.value.trim())} variant="outlined" fullWidth={true}/>
					<TextField label={_('common.vat_number')} error={!isVatIdValid} helperText={isVatIdValid ? '' : _('common.vat_number_invalid_info')} type="text" value={vatId} onChange={e => setVatId(e.target.value.trim())} variant="outlined" fullWidth={true}/>
					<TextField label={_('common.email')} error={!isEmailValid} helperText={isEmailValid ? '' : _('common.email_invalid_info')} type="text" value={email} onChange={e => setEmail(e.target.value.trim())} variant="outlined" fullWidth={true}/>
					<TextField label={_('common.phone')} error={!isPhoneValid} helperText={isPhoneValid ? '' : _('common.phone_invalid_info')} type="text" value={phone} onChange={e => setPhone(e.target.value)} variant="outlined" fullWidth={true}/>
					<div>
						<Button variant="contained" color="primary" type="submit">{_('common.proceed_to_checkout')}</Button>
					</div>
				</form>
			</Card>
		</div>
	);
}

