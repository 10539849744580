import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StatusIcon from "../research/StatusIcon";
import {Tooltip} from "@material-ui/core";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: props => ({
		borderRight: props.noborder ? "none" : "1px solid #DBE5EE",
		float: "left",
		paddingRight: 32,
		marginRight: 32,
		"& .icon-bg": {
			background: props.iconBg + " 0% 0% no-repeat padding-box",
			borderRadius: 8,
			display: props.noicon ? "none" : "block",
			float: "left",
			marginRight: 16,
			"& .icon": {
				width: 24,
				height: 24,
				margin: 12,
				background: "transparent url('/icon/" + props.icon + ".png') 0% 0% no-repeat padding-box",
			},
		},
		"& .count": {
			float: "left",
			"& h1": {
				margin: 0,
				"& span": {
					font: "normal normal normal 13px/20px 'Montserrat'",
					color: "#627A97",
					position: "relative",
					top: -3
				}
			},
			"& em": {
				font: "normal normal normal 11px/18px 'Montserrat'",
				fontStyle: "oblique",
				color: "#627A97",
			}
		}
	}),
	iconInfo: {
		display: "inline-block",
		background: "#627A97 0% 0% no-repeat padding-box",
		marginLeft: 8,
		color: "#fff",
		width: 16,
		height: 16,
		font: "normal normal 800 14px/18px Raleway",
		textAlign: "center",
		textTransform: "none",
		borderRadius: 8,
		cursor: "pointer"
	}
});

export default function HeaderPanelItem(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			{props.label === _('common.status') && <StatusIcon status={props.text1} marginRight={16}/>}
			{props.label !== _('common.status') && <div className="icon-bg"><div className="icon"> </div></div>}
			<div className="count">
				<span className="block-label">
					{props.label}
					{props.tooltipText && <Tooltip title={props.tooltipText}><a className={classes.iconInfo}>i</a></Tooltip>}
				</span>
				<h1>{props.text1} {props.text2 && <span>/ {props.text2}</span>}</h1>
				{props.text3 && <em>{props.text3}</em>}
			</div>
		</div>
	);
}

function Text2(props)
{
	if (props.isEmpty)
	{
		return '';
	}

	return (<span>/ {props.text}</span>)
}
