import React from "react";
import BlockLabels from "../BlockLabels";
import BlockWaitingForRespondents from "./BlockWaitingForRespondents";
import BlockResearches from "../research/BlockResearches";
import {_} from "../utils/l10n";

export default function WaitingForRespondents(props) {
	return (
		<React.Fragment>
			<BlockLabels text1={_('common.respondents_information')} margin="32px 0 16px 32px"/>
			<BlockWaitingForRespondents client={props.client} measurement={props.measurement}/>
			<BlockResearches client={props.client} measurement={props.measurement} finishedOnly={true}/>
		</React.Fragment>
	);
}