import {Box, Button} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Alert} from "@material-ui/lab";
import {
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint,
	commonUtils_IsAuthenticated,
	commonUtils_PasswordRecoveryRequestUrl,
	commonUtils_RedirectTo,
	commonUtils_SignUpUrl
} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import CustomCheckbox from "../customElements/CustomCheckbox";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	textField: {
		marginBottom: 30,
		borderRadius: 8
	},
	btnBox: {
		marginTop: 32,
		marginBottom: 32
	},
	alert: {
		marginBottom: 32,
		borderRadius: 24
	}
});

function loginUser(user)
{
	const url = new URL(document.location.href);

	localStorage.setItem('u', JSON.stringify(user));

	let target = "/";

	if (url.searchParams.has("t"))
	{
		target = decodeURIComponent(url.searchParams.get("t"));

		if (target.match(/(sign-|password-recovery)/))
		{
			target = "/";
		}
	}

	commonUtils_RedirectTo(target);
}

export default function SignInForm(props) {
	if (commonUtils_IsAuthenticated())
	{
		commonUtils_RedirectTo("/");
	}

	const classes = useStyles();

	const url = new URL(window.location.href);
	let defaultAlert = "";
	let defaultAlertType = "info";

	if (url.searchParams.has('recovery_success'))
	{
		defaultAlert = _('sign.password_change_success');
		defaultAlertType = "success";
	}

	if (url.searchParams.has('activation_success'))
	{
		defaultAlert = _('sign.account_activation_success');
		defaultAlertType = "success";
	}

	if (url.searchParams.has('signed_out'))
	{
		defaultAlert = _('sign.out_success');
		defaultAlertType = "success";
	}

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [keep, setKeep] = useState(false);
	const [alert, setAlert] = useState(defaultAlert);
	const [alertType, setAlertType] = useState(defaultAlertType);
	const [atinAttempts, setAtinAttempts] = useState(0);

	if (url.searchParams.has('at') && atinAttempts === 0)
	{
		setAtinAttempts(atinAttempts => ++atinAttempts);
		layoutUtils_LoadingStart();

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_SIGN_ATIN),
			commonUtils_GetApiCallOptions({t: url.searchParams.get('at')})
		)
		.then(blob => blob.json())
		.then(response => {
			if (response.statusCode === 200)
			{
				loginUser(response.data.user);
				return;
			}
			else
			{
				setAlert(_('sign.authentication_failed'));
				setAlertType("warning");
			}

			layoutUtils_LoadingEnd();
		})
		.catch(e => {
			console.log(e);
			layoutUtils_LoadingEnd();
			return e;
		});
	}

	function handleSubmit(event)
	{
		event.preventDefault();

		if (email && password)
		{
			layoutUtils_LoadingStart();

			fetch(
				commonUtils_GetApiEndpoint(process.env.REACT_APP_API_SIGN_IN),
				commonUtils_GetApiCallOptions({e: email, k: password, p: keep})
			)
			.then(blob => blob.json())
			.then(response => {
				if (response.statusCode === 200)
				{
					loginUser(response.data.user);
				}
				else if (response.statusCode === 401)
				{
					setAlert(_('sign.in_bad_credentials'));
					setAlertType("warning");
				}
				else
				{
					setAlert(_('sign.in_failed'));
					setAlertType("warning");
					console.log(response.statusMessage);
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
		}
	}

	return (
		<React.Fragment>
			{alert && <Alert severity={alertType} className={classes.alert}>{alert}</Alert>}
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<TextField label={_('sign.email', {"^":1})} type="email" value={email} onChange={event => setEmail(event.target.value)} autoFocus className={classes.textField} variant="outlined" fullWidth={true}/>
				<TextField label={_('sign.password', {"^":1})} type="password" value={password} onChange={event => setPassword(event.target.value)} className={classes.textField} variant="outlined" fullWidth={true}/>
				<FormControlLabel control={<CustomCheckbox checked={keep} onChange={() => setKeep(!keep)}/>} label={_('sign.keep', {"^":1})} />
				<Box className={classes.btnBox}>
					<Button variant="contained" color="primary" size="large" type="submit">
						{_('sign.in', {"^":1})}
					</Button>
				</Box>
				<Box>
					<Link href={commonUtils_SignUpUrl()} color="inherit">{_('sign.create_account', {"^":1})}</Link>
					<span> | </span>
					<Link href={commonUtils_PasswordRecoveryRequestUrl()} color="inherit">{_('sign.forgot_password', {"^":1})}</Link>
				</Box>
			</form>
		</React.Fragment>
	);
}
