import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
	root: {
		textAlign: "center",
		"& p": {
			font: "normal normal bold 16px/25px Montserrat",
			color: "#0162CA",
			textTransform: "uppercase",
			marginTop: 16,
			marginBottom: 4
		},
		"& small": {
			font: "normal normal normal 11px/15px Open Sans",
			color: "#627A97",
			display: "block",
			marginTop: 0,
			marginBottom: 24
		}
	}
});

export default function Result_Question_Resource_Body(props) {
	const classes = useStyles(props);

	return (
		<div className={clsx([classes.root, "block-result-resource-summary"])} id={"QRS_" + props.questionId + "_" + props.resourceId}>

		</div>
	);
}