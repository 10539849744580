import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "../Logo";
import HeaderToolbox from "./HeaderToolbox";
import HeaderPanel from "./HeaderPanel";
import {commonUtils_SetTopMargin} from "../utils/common.utils";
import {Menu, MenuItem} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import HeaderMenu from "./HeaderMenu";

const useStyles = makeStyles({
	root: {
		width: 1240,
		minHeight: 74,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: "0px 0px 24px 24px",
		position: "fixed",
		top: 0,
		left: "50%",
		marginLeft: -620,
		zIndex: 2,
		overflow: "hidden",
	}
});

export default function Header(props) {
	const classes = useStyles();

	useEffect(() => {
		commonUtils_SetTopMargin();
	});

	return (
		<div className={classes.root} id="header">
			<Logo width="118px" margin="16px 24px 16px 40px" client={props.client} measurement={props.measurement}/>
			<HeaderMenu/>
			<HeaderToolbox/>
			<div className="clearfix"> </div>
			<HeaderPanel
				client={props.client}
				measurement={props.measurement}
				research={props.research}
				isIntegration={props.isIntegration}
				isWaitingForRespondents={props.isWaitingForRespondents}
				isDashboard={props.isDashboard}
				isResearchDefinition={props.isResearchDefinition}
				isSummary={props.isSummary}
				isPayment={props.isPayment}
				isDetail={props.isDetail}
				isProfile={props.isProfile}
			/>
		</div>
	);
}
