import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Modal} from "@material-ui/core";
import {researchUtils_GetResources, researchUtils_RenderResultsExample} from "../utils/research.utils";
import {layoutUtils_SetModalHeight} from "../utils/layout.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	modalWindow: {
		position: 'absolute',
		width: 1180,
		maxWidth: "84%",
		borderRadius: 24,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		padding: "24px 32px 40px 32px",
		"& h5": {
			font: "normal normal normal 16px/25px Montserrat",
			color: "#000000",
			padding: "0 0 24px 0",
			borderBottom: "1px solid #DBE5EE",
			margin: 0,
			marginBottom: 24
		},
		"& h6": {
			font: "normal normal normal 16px/25px Montserrat",
			color: "#000000",
			margin: 0,
			marginBottom: 24
		},
		"& .close": {
			position: "absolute",
			right: 32,
			top: 24,
			zIndex: 999
		},
		"& .modal-content": {
			width: "100%",
			maxHeight: 400,
			overflow: "scroll",
			"-ms-overflow-style": "none",  /* IE and Edge */
			"scrollbar-width": "none"  /* Firefox */
		},
		"& .modal-content::-webkit-scrollbar": { /* Chrome, Safari and Opera */
			display: "none"
		}
	}
});

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		overflow: "hidden"
	};
}

export default function ResultPreviewModal(props) {
	const classes = useStyles(props);
	const [modalStyle] = useState(getModalStyle);
	const resultBlockId = "QRD_" + props.question.id.value;

	if (props.open)
	{
		const chartType = props.question.htmlType.value + (props.question.chartType ? props.question.chartType.value : "");

		let inte = setInterval(function(){
			if (document.getElementById(resultBlockId))
			{
				clearInterval(inte);
				researchUtils_RenderResultsExample(props.question, researchUtils_GetResources(props.research), chartType);
				layoutUtils_SetModalHeight();
			}
		}, 100);
	}

	return (
		<Modal open={props.open} onClose={props.handleClose} disableEnforceFocus disableAutoFocus>
			<div style={modalStyle} className={classes.modalWindow}>
				<a href="#" onClick={e => props.handleClose(e)} className="close"><img src="/icon/cross.png" alt={_("common.close")}/></a>
				<div>
					<h5>{_("research.question_results_example", {"^": 1})}</h5>
					<div className={"modal-content" + (props.open ? " modal-content-opened" : "")}>
						<h6>{props.question.orderTitle.value}</h6>
						<div id={resultBlockId}> </div>
					</div>
				</div>
				<div className="preview-watermark"> </div>
			</div>
		</Modal>
	);
}
