import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {generateQuestionName} from "../PreviewUtils";
import {TextField} from "@material-ui/core";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	field: {
		width: "30%",
		boxSizing: "border-box",
		margin: 0,
		marginRight: "3%",
		display: "inline-block"
	}
});

export default function TextPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);

	return (
		<React.Fragment>
			<h5>{_('research.question_preview')}</h5>
			<h6>{questionName}</h6>
			<div className={classes.field}>
				<TextField label={_('common.answer')} variant="filled" fullWidth={true} />
			</div>
		</React.Fragment>
	);
}