import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {generateQuestionName} from "../PreviewUtils";
import {_} from "../utils/l10n";


const useStyles = makeStyles({
	answerOption: {
		float: "left",
		width: "50%"
	}
});

export default function CheckboxListPreview(props) {
	const classes = useStyles(props);
	const questionName = generateQuestionName(props);
	const checkboxes = [];
	let numChecked = 0;

	for (let i in props.question.options.value)
	{
		let check = numChecked > 2 ? false : Math.random() < 3 / Object.keys(props.question.options.value).length;
		numChecked += check ? 1 : 0;

		checkboxes.push(
			<div className={classes.answerOption} key={i}><FormControlLabel control={<Checkbox disabled={true} checked={check} color="primary"/>} label={props.question.options.value[i]}/></div>
		);
	}

	return (
		<React.Fragment>
			<h5>{_('research.question_preview')}</h5>
			<h6>{questionName}</h6>
			{checkboxes}
		</React.Fragment>
	);
}