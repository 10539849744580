import {commonUtils_GetLocationHashArgs, commonUtils_SetLocationHashArg} from "./common.utils";

export function filterUtils_GetResultFilter()
{
	return commonUtils_GetLocationHashArgs()["rf"] || {};
}

export function filterUtils_SetResultFilter(filter)
{
	commonUtils_SetLocationHashArg('rf', filter);
}

export function filterUtils_GetFromForm()
{
	let i, name, value;
	const elems = document.querySelectorAll("#resultFilter input[type='checkbox']:checked");
	const filter = {};

	for (i in elems)
	{
		if (elems.hasOwnProperty(i))
		{
			name = elems[i].attributes.name.nodeValue.replace(/[\[\]]/g, '');
			value = elems[i].attributes.value.nodeValue;

			filter[name] = filter[name] || [];
			filter[name].push(value);
		}
	}

	return filter;
}