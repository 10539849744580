import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderPanelItem from "./HeaderPanelItem";
import {Button, TextField} from "@material-ui/core";
import {
	commonUtils_CheckAuthorized,
	commonUtils_GetApiCallOptions,
	commonUtils_GetApiEndpoint, commonUtils_GetClient, commonUtils_GetCountry,
	commonUtils_GetUser, commonUtils_MeasurementUrl,
	commonUtils_NumberFormat,
	commonUtils_RedirectTo, commonUtils_SetData,
	commonUtils_SummaryUrl
} from "../utils/common.utils";
import {layoutUtils_LoadingEnd, layoutUtils_LoadingStart} from "../utils/layout.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		borderTop: "1px solid #DBE5EE",
		padding: "24px 0 32px 0",
		margin: 40,
		marginTop: 0,
		marginBottom: 0,
		"& .research-name-edit": {
			width: 550,
			position: "relative",
			display: "none",
			"& button": {
				display: "block",
				borderRadius: 8,
				boxShadow: "0px 4px 16px #022B5729",
				padding: "4px 8px",
				position: "absolute",
				bottom: 5,
				right: 5,
				fontWeight: "bolder"
			}
		},
		"& .research-name": {
			float: "left",
			position: "relative",
			"& .icon-edit": {
				background: "transparent url(/icon/more.svg) center center no-repeat padding-box",
				width: 24,
				height: 24,
				display: "block",
				position: "absolute",
				right: 24,
				bottom: 8,
				zIndex:999,
				opacity: 0.4
			}
		}
	},
	buttons: {
		position: "absolute",
		top: 98,
		right: 40,
		width: 260,
		"& button": {
			display: "block",
			float: "right",
			borderRadius: 8,
			boxShadow: "0px 4px 16px #022B5729",
			padding: "13px 32px"
		},
		"& .checkout": {
			marginTop: 80
		},
		"& .checkout-error-msg": {
			position: "absolute",
			top: 136,
			right: 0,
			width: 1000,
			textAlign: "right"
		}
	}
});

export default function HeaderPanel(props) {
	const classes = useStyles();
	const country = commonUtils_GetCountry();
	const user = commonUtils_GetUser();
	const client = commonUtils_GetClient();

	const [researchName, setResearchName] = useState(props.research ? props.research.name.value : "");

	function handleSummary(event) {
		event.preventDefault();
		commonUtils_RedirectTo(commonUtils_SummaryUrl(props.measurement.id, props.research.id.value));
	}

	function showEditName(e)
	{
		e.preventDefault();

		e.target.style.display = "none";
		e.target.parentNode.querySelector("h1").style.display = "none";
		document.querySelector(".research-name-edit").style.display = "block";
	}

	function saveResearchName(e)
	{
		e.preventDefault();

		layoutUtils_LoadingStart();

		fetch(
			commonUtils_GetApiEndpoint(process.env.REACT_APP_API_RESEARCH_SET),
			commonUtils_GetApiCallOptions({
				uid: user.id,
				token: user.token,
				co: country.code,
				cid: client.id,
				mid: props.measurement.id,
				rid: props.research.id.value,
				key: 'name',
				value: researchName
			})
		)
			.then(blob => blob.json())
			.then(response => {
				if (commonUtils_CheckAuthorized(response))
				{
					if (response.statusCode === 200)
					{
						document.querySelector(".research-name a").style.display = "block";
						document.querySelector(".research-name h1").style.display = "block";
						document.querySelector(".research-name h1").innerText = researchName;
						document.querySelector(".research-name-edit").style.display = "none";

						commonUtils_SetData(response.data);
					}
					else if (response.statusCode === 406 && response.statusMessage === 'LACK_OF_RESPONDENTS')
					{
						commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.mid));
					}
				}

				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
	}

	if (!props.isIntegration() && !props.isWaitingForRespondents() && !props.isProfile())
	{
		const mbClass = !props.isSummary() && !props.isPayment() ? "mb24" : null;
		const showResearchName = props.isResearchDefinition()
			|| props.isSummary() || props.isPayment() || props.isDetail();
		const showResearchStatus = props.isResearchDefinition() || props.isDetail();

		return (
			<div id="header_panel" className="slide-down">
				<div className={classes.root}>
					{showResearchName && <div className={mbClass}>
						<div className="research-name">
							<HeaderPanelItem label={_('research.name', {"^": 1})} text1={props.research.name.value} noborder noicon/>
							{props.research.paid === undefined && <a href="#" className="icon-edit" title={_('research.change_name', {"^": 1})} onClick={showEditName}> </a>}
						</div>
						<div className="research-name-edit">
							<TextField variant="outlined" fullWidth={true} value={researchName} onChange={e => setResearchName(e.target.value)} size="small"/>
							<Button type="button" variant="contained" color="primary" size="small" onClick={saveResearchName}>{_('common.save')}</Button>
						</div>

						<div className="clearfix"> </div>
					</div>}

					<div className="not-printable">
						{showResearchStatus &&
						<HeaderPanelItem label={_('common.status')} text1={_('common.status_' + props.research.status.value)}/>}

						{props.isDashboard() &&
						<HeaderPanelItem
							iconBg="#CDF7E7"
							icon="face-smile-green"
							label={_('common.respondent', {num: 2, "*": "num"})}
							text1={props.measurement.activeRespondents[country.code]}
							text2={props.measurement.minRespondents}
						/>}
						{(props.isResearchDefinition() || props.isDetail()) &&
						<HeaderPanelItem
							iconBg="#CDF7E7"
							icon="face-smile-green"
							label={_('common.respondent', {num: 2, "*": "num"})}
							text1={props.research.paneluids.value}
						/>}

						{props.isDashboard() &&
						<HeaderPanelItem
							iconBg="#CDF7E7"
							icon="graph-green"
							label={_('common.research', {num: 2, "*": "num"})}
							text1={props.measurement.numResearches}/>}

						{(props.isResearchDefinition() || props.isDetail()) &&
						<HeaderPanelItem iconBg="#CDF7E7"
										 icon="message-green"
										 label={_('common.question', {num: 2, "*": "num"})}
										 text1={props.research.selectedQuestionPatternIds.value.length}
										 text2={props.research.questionPatterns.value.length}/>}

						{(props.isResearchDefinition() || props.isDetail()) &&
						<HeaderPanelItem iconBg="#FFEACC"
										 icon="price-orange"
										 label={_('common.price')}
										 text1={commonUtils_NumberFormat(props.research.wholePrice.value) + " " + props.research.currency.value}
										 tooltipText={_('common.vat_xx_perc_excluded', {perc: props.research.vatPerc.value})}
										 noborder/>}
						<div className="clearfix"> </div>
					</div>
					{(props.isResearchDefinition())
						&& <div className={classes.buttons + " not-printable"}>
							<Button variant="contained" color="primary" className="checkout"
									disabled={!props.research.isValid}
									onClick={handleSummary}>{_('research.go_to_checkout')}</Button>
					</div>}
				</div>
			</div>
		);
	}

	return null;
}