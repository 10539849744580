import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {
	commonUtils_AddClass,
	commonUtils_GetCountry,
	commonUtils_NumberFormat
} from "../utils/common.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		padding: "16px 32px",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: "100%",
		boxSizing: "border-box",
		float: "left",
		"& .question": {
			width: 450,
			float: "left",
			lineHeight: 5,
			font: "normal normal normal 16px/25px 'Montserrat'",
			letterSpacing: 0,
			color: "#000000"
		},
		"& .number": {
			width: 155,
			float: "left",
			background: "#DBE5EE80 0% 0% no-repeat padding-box",
			borderRadius: 8,
			marginRight: 16,
			textDecoration: "none",
			"& *": {
				textAlign: "center"
			},
			"& h5": {
				font: "normal normal normal 16px/25px 'Montserrat'",
				letterSpacing: 0,
				color: "#000000",
				marginTop: 16,
				marginBottom: 8
			},
			"& h6": {
				font: "normal normal normal 14px/18px 'Montserrat'",
				letterSpacing: 0,
				color: "#5C7692",
				marginTop: 0,
				marginBottom: 16
			}
		},
		"& .number.last": {
			marginRight: 0
		},
		"& .number.selected": {
			border: "1px solid #0162CA",
			background: "#0162CA1A 0% 0% no-repeat padding-box"
		},
		"& .number.inactive": {
			background: "#F1F5F8 0% 0% no-repeat padding-box",
			"& h5,h6": {
				color: "#DBE5EE"
			}
		},
	}
});

export default function BlockRespondents(props) {
	const classes = useStyles(props);

	const country = commonUtils_GetCountry();
	const priceLevels = props.research.numRespondentsBasePriceLevels.value;
	const activeRespondents = props.measurement.activeRespondents[country.code];
	const options = [];
	let selectedPrice;

	for(let r in priceLevels)
	{
		if (priceLevels.hasOwnProperty(r))
		{
			let price = priceLevels[r];
			let className = "number num-respondents";
			let isActive = true;
			let id = "respondents_" + r;

			if (parseInt(r) === props.research.paneluids.value)
			{
				className += " selected";
				selectedPrice = price;
			}

			if (r > activeRespondents)
			{
				isActive = false;
				className += " inactive";
			}

			options.push(
				<a href="#" className={className} onClick={props.handleNumRespondentsChange} id={id} key={r} data-value={r}>
					<h5 data-value={r}>{r}</h5>
					<h6 data-value={r}>{commonUtils_NumberFormat(price)} {props.research.currency.value}</h6>
				</a>);
		}
	}

	useEffect(() => {
		commonUtils_AddClass(document.querySelector(".num-respondents:last-child"), 'last');
	});

	return (
		<Card className={classes.root}>
			<div className="question">
				{_('research.how_many_respondents_to_use')}
			</div>
			{options}
		</Card>
	);
}