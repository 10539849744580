import React from "react";
import BlockLabels from "../BlockLabels";
import BlockNewResearchForm from "./BlockNewResearchForm";
import BlockResearches from "./BlockResearches";
import {_} from "../utils/l10n";

export default function NewResearch(props) {

	const labels = props.measurement.numResearches > 0
			? <BlockLabels text1={_('research.new')} text2={_('common.research')} mr1="0" mr2="430" margin="32px 0 16px 32px"/>
			: <BlockLabels text1={_('research.new')} margin="32px 0 16px 32px"/>;

	return (
		<React.Fragment>
			{labels}
			<BlockNewResearchForm client={props.client} measurement={props.measurement}/>
			<BlockResearches client={props.client} measurement={props.measurement}/>
		</React.Fragment>
	);
}