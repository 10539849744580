import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import ProgressBar from "../ProgressBar";
import InfoBox from "../InfoBox";
import {commonUtils_GetCountry} from "../utils/common.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		padding: 40,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: 400,
		boxSizing: "border-box",
		float: "left",
		marginRight: 32
	},
	col1: {
		width: "100%",
		float: "left",
		boxSizing: "border-box",
		"& .header": {
			"& .icon-bg": {
				background: "#FFEACC 0% 0% no-repeat padding-box",
				borderRadius: 8,
				float: "left",
				marginRight: 16,
				"& .icon": {
					width: 24,
					height: 24,
					margin: 12,
					background: "transparent url('/icon/face-smile.png') 0% 0% no-repeat padding-box",
				}
			},
			"& .count": {
				"& h1": {
					margin: 0,
					"& span": {
						font: "normal normal normal 13px/20px 'Montserrat'",
						color: "#627A97",
						position: "relative",
						top: -3
					}
				}
			}
		}
	}
});

export default function BlockWaitingForRespondents(props) {
	const classes = useStyles(props);
	const country = commonUtils_GetCountry();

	const progressFinished = Math.ceil(
		100 * props.measurement.activeRespondents[country.code]
			/ props.measurement.minRespondents);

	return (
		<Card className={classes.root}>
			<div className={classes.col1}>
				<div className="header">
					<div className="icon-bg">
						<div className="icon"> </div>
					</div>
					<div className="count">
						<span className="block-label">{_('common.respondent', {num: 2, "*": "num"})}</span>
						<h1>{props.measurement.activeRespondents[country.code] || 0}
								<span>/ {props.measurement.minRespondents}</span></h1>
					</div>
					<ProgressBar
						margin="16px 0 24px 0"
						color="#FF9800"
						finished={progressFinished}/>
				</div>
				<div className="info">
					<p className="body1-black">{_('common.looking_for_respondents')}</p>
					<InfoBox text={_('common.waiting_for_respondents_info', {min: props.measurement.minRespondents})} icon="email" margin="32px 0 0 0"/>
				</div>
			</div>
		</Card>
	);
}