import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Result_Question_Resource_Header from "./Result_Question_Resource_Header";
import Result_Question_Resource_Body from "./Result_Question_Resource_Body";

const useStyles = makeStyles({
	root: props => ({
		width: 260,
		float: "left",
		marginLeft: props.marginLeft
	})
});

export default function Result_Question_Resource(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<Result_Question_Resource_Header name={props.name}/>
			<Result_Question_Resource_Body questionId={props.questionId} resourceId={props.resourceId}/>
		</div>
	);
}