import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: {
		borderBottom: "1px solid #DBE5EE",
		"& h4": {
			marginTop: 24,
			marginBottom: 16,
			textAlign: "center",
			font: "normal normal normal 16px/25px Montserrat"
		}
	}
});

export default function Result_Question_Resource_Header(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<h4>{props.name}</h4>
		</div>
	);
}