import React, {useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {commonUtils_GetApiCallOptions, commonUtils_GetApiEndpoint, commonUtils_RedirectTo} from "../utils/common.utils";
import {layoutUtils_LoadingEnd} from "../utils/layout.utils";
import {researchUtils_RenderTagCloudDetail} from "../utils/research.utils";

const useStyles = makeStyles({
	root: {

	}
});

export default function ExampleTagCloud7NoCleaning(props) {
	const classes = useStyles();

	useEffect(function (){
		fetch(
			commonUtils_GetApiEndpoint('example/example'),
			commonUtils_GetApiCallOptions({e: "ExampleTagCloud7NoCleaning"})
		)
			.then(blob => blob.json())
			.then(response => {
				researchUtils_RenderTagCloudDetail(7, response.data);
				layoutUtils_LoadingEnd();
			})
			.catch(e => {
				console.log(e);
				layoutUtils_LoadingEnd();
				return e;
			});
	});

	return (
		<div>
			<h1>Bez čištění</h1>
			<h4>Která tři slovní spojení vás napadnou ve spojení se značkou?</h4>
			<p>Pouze převedení na lower-case a rozdělení více frází, pokud je zadal do jedoho políčka oddělené čárkou.</p>
			<div id="QRD_7"> </div>
		</div>

	);
}
