import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: props => ({
		margin: props.margin,
		position: "relative",
		height: 20,
		"& .block-label": {
			position: "absolute",
			top: 0
		}
	})
});

export default function BlockLabels(props) {
	const classes = useStyles(props);
	const itemIndexes = ['1', '2', '3', '4', '5'];

	return (
		<div className={classes.root}>
			{itemIndexes.map((value, index) => {
				if (typeof props['text' + index] !== 'undefined')
				{
					let mr = parseInt(props['mr' + index]) || 0;

					return <span className="block-label" style={{left: mr}} key={index}>{props['text' + index]}</span>
				}
			})}
		</div>
	);
}