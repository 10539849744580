import React from "react";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import BlockLabels from "../BlockLabels";
import QRCode from "qrcode.react";
import {
	commonUtils_DownloadCurrentAsPdf,
	commonUtils_MeasurementUrl,
	commonUtils_NumberFormat, commonUtils_RedirectTo
} from "../utils/common.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: {
		"& .thankyou": {
			padding: 40,
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			boxShadow: "0px 8px 24px #114E9114",
			borderRadius: 24,
			width: 768,
			boxSizing: "border-box",
			float: "left",
			marginRight: 32,
			marginTop: 32,
			"& h1": {
				font: "normal normal bold 25px/35px Montserrat",
				color: "#000000",
				padding: 0,
				margin: 0
			},
			"& p": {
				margin: 0,
				padding: "24px 0 32px 0",
				borderBottom: "1px solid #DBE5EE"
			},
			"& .col": {
				float: "left",
				"& h3": {
					padding: 0,
					margin: 0,
					font: "normal normal bold 16px/25px Montserrat",
					color: "#000000"
				},
				"& h6": {
					margin: 0,
					padding: 0,
					font: "normal normal normal 13px/16px Montserrat",
					color: "#5C7692"
				}
			},
			"& .col1": {
				width: 450
			},
			"& .col2": {
				width: 230
			},
			"& .foot": {
				float: "left",
				width: "100%",
				borderTop: "1px solid #DBE5EE",
				marginTop: 32,
				paddingTop: 32,
				"& >span": {
					font: "normal normal normal 16px/25px Montserrat",
					color: "#000000",
					display: "inline-block",
					marginRight: 30
				},
				"& button": {
					background: "#0162CA 0% 0% no-repeat padding-box",
					boxShadow: "0px 4px 16px #022B5729",
					borderRadius: 8,
					padding: "16px 32px"
				}
			},
			"& .invoice-btn": {
				background: "#CCE0F5 url('/icon/box-import-blue.png') 50% 24px no-repeat",
				width: 120,
				height: 96,
				display: "block",
				borderRadius: 8,
				marginTop: 32,
				float: "right",
				paddingTop: 56,
				boxSizing: "border-box",
				textTransform: "uppercase",
				textAlign: "center",
				textDecoration: "none",
				font: "normal normal normal 13px/16px Montserrat",
				color: "#0162CA"
			}
		},
		"& .qrcode": {
			padding: 40,
			background: "#FFFFFF 0% 0% no-repeat padding-box",
			boxShadow: "0px 8px 24px #114E9114",
			borderRadius: 24,
			width: 400,
			boxSizing: "border-box",
			float: "left",
			marginRight: 0,
			marginBottom: 64,
			marginTop: 32,
			textAlign: "center",
			"& h2": {
				font: "normal normal bold 25px/35px Montserrat",
				color: "#000000",
				margin: "16px 0 0 0"
			},
			"& h6": {
				margin: 0,
				padding: 0,
				font: "normal normal normal 13px/16px Montserrat",
				color: "#5C7692"
			}
		}
	}
});

export default function ResearchPayment(props) {
	const classes = useStyles(props);
	const vs = props.research.vs.value;

	function handleGoToDashboard(event)
	{
		event.preventDefault();

		commonUtils_RedirectTo(commonUtils_MeasurementUrl(props.measurement.id));
	}

	return (
		<div className={classes.root}>
			<Card className="thankyou">
				<h1>{_('research.thank_you_for_order_xx', {vs: vs})}</h1>
				<p>{_('research.research_start_after_payment')}</p>
				<div className="col col1">
					<BlockLabels text1={_('common.bank_account_number')} mr1="0" margin="32px 0 0 0"/>
					<h3>{process.env.REACT_APP_BANK_ACCOUNT}</h3>
					<BlockLabels text1={_('common.variable_symbol')} mr1="0" margin="24px 0 0 0"/>
					<h3>{vs}</h3>
					<BlockLabels text1={_('common.amount')} mr1="0" margin="24px 0 0 0"/>
					<h3>{commonUtils_NumberFormat(props.research.totalPrice.value)} {props.research.currency.value}</h3>
					<h6>({_('common.due_date_xx', {date: props.research.dueDate.value.date.split(" ")[0]})})</h6>
				</div>
				<div className="col col2 not-printable">
					<a href="#" onClick={commonUtils_DownloadCurrentAsPdf} className="invoice-btn" data-filename={"h24-payment-" + props.research.name.value}>{_('common.invoice')}</a>
				</div>
				<div className="foot not-printable">
					<span>{_('research.we_will_inform_you_about_research_progress')}</span>
					<Button variant="contained" color="primary" type="submit" onClick={handleGoToDashboard}>{_('common.to_dashboard')}</Button>
				</div>
			</Card>
			<Card className="qrcode">
				<BlockLabels text1={_('common.qr_payment')} mr1="116" margin="88px 0 16px 0"/>
				<QRCode value={props.research.qr.value} size={180} className="qrcode-image" data-source={props.research.qr.value}/>
				<h2 className="not-printable">{commonUtils_NumberFormat(props.research.totalPrice.value)} {props.research.currency.value}</h2>
				<h6 className="not-printable">({_('common.due_date_xx', {date: props.research.dueDate.value.date.split(" ")[0]})})</h6>
			</Card>
		</div>
	);
}
