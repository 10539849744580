import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Menu, MenuItem} from "@material-ui/core";
import {_} from "../utils/l10n";
import {
	commonUtils_CurrentUrlWithParameter, commonUtils_GetClient,
	commonUtils_GetCountry,
	commonUtils_RedirectTo
} from "../utils/common.utils";

const useStyles = makeStyles({
	root: {
		float: "left",
		marginTop: 16,
		width: 200,
		borderLeft: "1px solid #DBE5EE",
		paddingLeft: 16,
		"& a": {
			font: "normal normal normal 13px/20px Montserrat",
			color: "#627A97",
			textTransform: "uppercase",
			cursor: "pointer"
		}
	}
});

export default function HeaderMenu() {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);

	const client = commonUtils_GetClient();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event, target) => {
		setAnchorEl(null);

		if (target && target !== 'backdropClick')
		{
			commonUtils_RedirectTo(target);
		}
	};

	return (
		<div className={classes.root}>
			<a aria-controls="header_menu" aria-haspopup="true" onClick={handleClick}>{_('common.country_' + commonUtils_GetCountry().code + '_name')}</a>
			<Menu id="header_menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				{client.countries.indexOf('cz') > -1 && <MenuItem component="a" href={commonUtils_CurrentUrlWithParameter('co', 'cz')}>{_('common.country_cz_name')}</MenuItem>}
				{client.countries.indexOf('sk') > -1 && <MenuItem component="a" href={commonUtils_CurrentUrlWithParameter('co', 'sk')}>{_('common.country_sk_name')}</MenuItem>}
			</Menu>
		</div>
	);
}