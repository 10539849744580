import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: {
		position: "relative",
		"& .link": {
			display: "block",
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: 20
		},
		"& .input": {
			display: "block",
			overflow: "hidden",
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			opacity: 0,
			cursor: "pointer",
			height: 21,
			"& input": {
				cursor: "pointer",
			}
		}
	}
});

function handleChange(e, i, cb1, cbForceRenderForm)
{
	cb1(e, i, cbForceRenderForm);
}

export default function FileUploader(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<a href="#" className="body2-grey-link link">{props.text}</a>
			<div className="input">
				<input
					type="file"
					accept="image/*"
					onChange={
						(e) => handleChange(e, props.resourceIndex, props.handleFileSelected, props.forceRenderForm)
					}/>
			</div>
		</div>
	);
}