import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
	root: props => ({
		margin: props.margin,
		width: "100%",
		"& .icon": {
			background: "transparent url('/icon/" + props.icon + ".png') 0% 0% no-repeat padding-box",
			width: 40,
			height: 34,
			float: "left",
			marginRight: 24
		},
		"& .text": {
			float: "left",
			color: "#5C7692",
			font: "normal normal medium 15px/23px 'Raleway'",
			width: 256
		}
	})
});

export default function InfoBox(props) {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className="icon"> </div>
			<div className="text">
				{props.text}
			</div>
		</div>
	);
}