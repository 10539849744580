import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import BlockLabels from "../BlockLabels";
import TextField from "@material-ui/core/TextField";
import FileUploader from "./FileUploader";
import ResourceImage from "./ResourceImage";
import {researchUtils_GetResources} from "../utils/research.utils";
import {commonUtils_GetResearch} from "../utils/common.utils";
import {_} from "../utils/l10n";

const useStyles = makeStyles({
	root: props => ({
		padding: 0,
		paddingBottom: 0,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 8px 24px #114E9114",
		borderRadius: 24,
		width: "100%",
		boxSizing: "border-box",
		float: "left",
		"& .col": {
			float: "left",
			padding: "24px 32px 16px 32px",
			"& .form": {
				borderTop: "1px solid #DBE5EE",
				width: 244,
				float: "left",
				"& .MuiTextField-root": {
					marginTop: 24
				},
				"& a": {
					font: "normal normal medium 15px/23px 'Raleway'",
					display: "block",
					width: "100%",
					textAlign: "center",
					textDecoration: "underline",
					color: "#5C7692",
				},
				"& .img": {
					width: "100%",
					height: 88,
					padding: 24,
					boxSizing: "border-box",
					textAlign: "center",
					"& img": {
						height: "100%",
						opacity: 0.2
					}
				},
				"& h5": {
					margin: 0,
					padding: 0,
					font: "normal normal normal 14px/25px Montserrat",
				}
			}
		},
		"& .col.col1": {
			width: 308,
			borderRadius: "24px 0px 0px 24px",
			boxSizing: "border-box",
			height: props.isEditable ? 264 : "auto",
		},
		"& .col.col2": {
			width: 892,
			backgroundColor: "#FCFDFF",
			background: "linear-gradient(90deg, rgba(244,248,251,1) 0%, rgba(252,253,255,1) 3%)",
			borderRadius: "0px 24px 24px 0px",
			boxSizing: "border-box",
			height: props.isEditable ? 264 : "auto",
			"& .form": {
				marginRight: 32,
				width: 254
			},
			"& .form.last": {
				marginRight: 0
			}
		}
	}),
});

export default function BlockBrandSettings(props) {
	const classes = useStyles(props);

	const resources = props.research.brands.value;

	const [isValid0, setIsValid0] = useState(true);
	const [isValid1, setIsValid1] = useState(true);
	const [isValid2, setIsValid2] = useState(true);
	const [isValid3, setIsValid3] = useState(true);

	const setterMap = {
		0: setIsValid0,
		1: setIsValid1,
		2: setIsValid2,
		3: setIsValid3
	}

	function handleTestEmpty(e, index)
	{
		const isValid = e.target.value.trim() !== '';

		setterMap[index](isValid);
	}

	function forceRenderFieldValues()
	{
		const resources = researchUtils_GetResources(commonUtils_GetResearch());
		let i;

		for (i in resources)
		{
			document.getElementById("brand" + i + "name").value = resources[i].value.name;
		}
	}

	return (
		<Card className={classes.root}>
			<div className="col col1">
				<BlockLabels text1={_('research.my_brand')} margin="0 0 16px 0"/>
				<div className="form" id="brand0">
					{resources[0] && props.isEditable &&
					<TextField
						type="text" size="small" fullWidth variant="outlined" label={_('common.name')} id="brand0name"
						defaultValue={resources[0].value.name}
						onChange={e => handleTestEmpty(e, 0)}
						onBlur={e => props.handleResourceChange(e, "brand0")}
						error={!isValid0}
						helperText={isValid0 ? '' : _('research.enter_brand_name')}
					/>}
					{resources[0] && !props.isEditable && <div><BlockLabels text1={_('common.name')} margin="16px 0 0 0"/> <h5>{resources[0].value.name}</h5></div>}
					<ResourceImage resource={resources[0] ? resources[0].value : false}/>
					{props.isEditable && <FileUploader text={resources[0] ? _('research.change_logo') : _('research.upload_logo')} resourceIndex={0} forceRenderForm={forceRenderFieldValues} handleFileSelected={props.handleFileSelected}/>}
				</div>
			</div>
			<div className="col col2">
				<BlockLabels text1={_('research.competitive_brand', {num: 2, "*": "num"})} margin="0 0 16px 0"/>
				<div className="form" id="brand1">
					{resources[1] && props.isEditable &&
					<TextField
						type="text" size="small" fullWidth variant="outlined" label={_('common.name')} id="brand1name"
						defaultValue={resources[1].value.name}
						onChange={e => handleTestEmpty(e, 1)}
						onBlur={e => props.handleResourceChange(e, "brand1")}
						error={!isValid1}
						helperText={isValid1 ? '' : _('research.enter_brand_name')}
					/>}
					{resources[1] && !props.isEditable && <div><BlockLabels text1={_('common.name')} margin="16px 0 0 0"/> <h5>{resources[1].value.name}</h5></div>}
					<ResourceImage resource={resources[1] ? resources[1].value : false}/>
					{props.isEditable && <FileUploader text={resources[1] ? _('research.change_logo') : _('research.upload_logo')} resourceIndex={1} forceRenderForm={forceRenderFieldValues} handleFileSelected={props.handleFileSelected}/>}
				</div>
				<div className="form" id="brand2">
					{resources[2] && props.isEditable &&
					<TextField
						type="text" size="small" fullWidth variant="outlined" label={_('common.name')} id="brand2name"
						defaultValue={resources[2].value.name}
						onChange={e => handleTestEmpty(e, 2)}
						onBlur={e => props.handleResourceChange(e, "brand2")}
						error={!isValid2}
						helperText={isValid2 ? '' : _('research.enter_brand_name')}
					/>}
					{resources[2] && !props.isEditable && <div><BlockLabels text1={_('common.name')} margin="16px 0 0 0"/> <h5>{resources[2].value.name}</h5></div>}
					<ResourceImage resource={resources[2] ? resources[2].value : false}/>
					{props.isEditable && <FileUploader text={resources[2] ? _('research.change_logo') : _('research.upload_logo')} resourceIndex={2} forceRenderForm={forceRenderFieldValues} handleFileSelected={props.handleFileSelected}/>}
				</div>
				<div className="form last" id="brand3">
					{resources[3] && props.isEditable &&
					<TextField
						type="text" size="small" fullWidth variant="outlined" label={_('common.name')} id="brand3name"
						defaultValue={resources[3].value.name}
						onChange={e => handleTestEmpty(e, 3)}
						onBlur={e => props.handleResourceChange(e, "brand3")}
						error={!isValid3}
						helperText={isValid3 ? '' : _('research.enter_brand_name')}
					/>}
					{resources[3] && !props.isEditable && <div><BlockLabels text1={_('common.name')} margin="16px 0 0 0"/> <h5>{resources[3].value.name}</h5></div>}
					<ResourceImage resource={resources[3] ? resources[3].value : false}/>
					{props.isEditable && <FileUploader text={resources[3] ? _('research.change_logo') : _('research.upload_logo')} resourceIndex={3} forceRenderForm={forceRenderFieldValues} handleFileSelected={props.handleFileSelected}/>}
				</div>
			</div>
		</Card>
	);
}
